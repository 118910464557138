import { FC, ReactNode } from "react";

interface IProps {
  children: ReactNode;
}

export const Note: FC<IProps> = ({ children }) => {
  return (
    <div className="bg-gray-50 p-1 text-sm text-gray-800 rounded-md w-fit">
      {children}
    </div>
  );
};
