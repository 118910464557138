import { Input, InputProps } from "@nextui-org/react";
import { FC } from "react";

interface IProps extends Omit<InputProps, "onValueChange"> {
  onValueChange: (value: number) => void;
}

export const InputField: FC<IProps> = (props) => {
  return (
    <Input
      {...props}
      onValueChange={(e) => props.onValueChange(Number(e))}
      type="number"
      classNames={{
        inputWrapper: "!bg-brand-700",
        label: "!text-white pe-0",
        input: "!text-white",
        innerWrapper: "text-sm",
      }}
    />
  );
};
