export const getDateDifference = (inputDate: string = "") => {
  if (!inputDate) return "";

  const date = new Date(inputDate);
  const currentDate = new Date();
  let totalMonthsDifference =
    (currentDate.getFullYear() - date.getFullYear()) * 12;
  totalMonthsDifference += currentDate.getMonth() - date.getMonth();

  if (currentDate.getDate() < date.getDate()) {
    totalMonthsDifference--;
  }

  const yearsDifference = Math.floor(totalMonthsDifference / 12);
  const monthsDifference = totalMonthsDifference % 12;

  return `${yearsDifference} ${
    yearsDifference === 1 ? "year" : "years"
  } ${monthsDifference} month`;
};

export const getDateDifferenceInDays = (date1?: string, date2?: string) => {
  if (!date1 || !date2) return 0;

  const date1Obj = new Date(date1);
  const date2Obj = new Date(date2);
  const diffTime = Math.abs(date2Obj.getTime() - date1Obj.getTime());
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
};
