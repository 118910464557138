import React from "react";
import ReactDOM from "react-dom/client";
import { NextUIProvider } from "@nextui-org/react";
import { Provider } from "react-redux";
import reportWebVitals from "./reportWebVitals";
import { store } from "./store/app/store";
import App from "./App";
import { initAmplitude } from "./analytics";
import "./index.css";

initAmplitude();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <NextUIProvider>
        <App />
      </NextUIProvider>
    </Provider>
  </React.StrictMode>
);

reportWebVitals();
