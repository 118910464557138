import { useAppSelector } from "@/store/app/hooks";
import { tripDataSelector } from "@/store/features/trip-info/tripInfoSelectors";
import { ReactComponent as DistanceIcon } from "@/assets/icons/distance.svg";
import { ReactComponent as DurationIcon } from "@/assets/icons/duration.svg";
import { ReactComponent as TollsIcon } from "@/assets/icons/tolls.svg";
import { ReactComponent as DieselIcon } from "@/assets/icons/diesel.svg";
import { MetricCard } from "@/components/metric-card";
import { IncludeDeadHead } from "./IncludeDeadHead";
import { OpenGoogleMaps } from "./OpenGoogleMaps";
import { Map } from "./Map";
import { useMemo } from "react";

export const MapView = () => {
  const tripData = useAppSelector(tripDataSelector);

  const { distance, duration, tollCost, fuelPrice, activeUnitSystem } =
    tripData || {};

  const activeDistance = useMemo(
    () =>
      activeUnitSystem === "imperial" ? distance.miles : distance.kilometers,
    [activeUnitSystem, distance.kilometers, distance.miles]
  );

  const currencySign = useMemo(
    () => (activeUnitSystem === "imperial" ? "$" : "C$"),
    [activeUnitSystem]
  );

  const activeFuelPrice = useMemo(
    () =>
      activeUnitSystem === "imperial" ? fuelPrice.gallon : fuelPrice.liter,
    [activeUnitSystem, fuelPrice.gallon, fuelPrice.liter]
  );

  const activeFuelTerm = useMemo(
    () => (activeUnitSystem === "imperial" ? "gl" : "l"),
    [activeUnitSystem]
  );

  const activeTollCost = useMemo(
    () => (activeUnitSystem === "imperial" ? tollCost.usd : tollCost.cad),
    [activeUnitSystem, tollCost.cad, tollCost.usd]
  );

  return (
    <div className="w-full h-full bg-text-primary relative">
      <OpenGoogleMaps />
      <Map />
      <div className="absolute bottom-0 left-0 right-0 p-3 df">
        <div className="grid grid-cols-2 gap-2">
          <IncludeDeadHead />
          <MetricCard
            icon={<DistanceIcon />}
            label="Distance"
            value={activeDistance}
          />
          <MetricCard
            icon={<DurationIcon />}
            label="Duration"
            value={duration}
          />
          <MetricCard
            icon={<TollsIcon />}
            label="Tolls"
            value={tollCost.usd && `${currencySign} ${activeTollCost}`}
          />
          <MetricCard
            icon={<DieselIcon />}
            label={`Diesel Price/${activeFuelTerm}`}
            value={fuelPrice && `${currencySign} ${activeFuelPrice}`}
          />
        </div>
      </div>
    </div>
  );
};
