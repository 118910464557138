import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ITripDataV2, TDistance, TDistanceDifference } from "@/types/common";

interface ITripSliceState extends ITripDataV2 {
  distanceDifference: TDistanceDifference;
  loading: boolean;
  activeUnitSystem: "imperial" | "metric";
  isUnitsSwitchAccessible: boolean;
}

const initialState: ITripSliceState = {
  activeUnitSystem: "imperial",
  isUnitsSwitchAccessible: false,
  loading: false,
  distance: {
    kilometers: "0",
    miles: "0",
    kilometersRaw: 0,
    milesRaw: 0,
  },
  distanceDifference: {
    kilometers: 0,
    miles: 0,
  },
  duration: "0",
  tollCost: {
    usd: "0",
    cad: "0",
  },
  fuelPrice: {
    gallon: "0",
    liter: "0",
  },
};

export const tripInfoSlice = createSlice({
  name: "tripInfo",
  initialState,
  reducers: {
    setActiveUnitSystem: (
      state,
      action: PayloadAction<"imperial" | "metric">
    ) => {
      state.activeUnitSystem = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setFuelPrice: (
      state,
      action: PayloadAction<{
        gallon: string;
        liter: string;
      }>
    ) => {
      state.fuelPrice = action.payload;
    },
    setTollCost: (
      state,
      action: PayloadAction<{
        usd: string;
        cad: string;
      }>
    ) => {
      state.tollCost = action.payload;
    },
    resetTollCost: (state) => {
      state.tollCost = {
        usd: "0",
        cad: "0",
      };
    },
    setDistance: (state, action: PayloadAction<TDistance>) => {
      state.distance = action.payload;
    },
    setDistanceDifference: (
      state,
      action: PayloadAction<TDistanceDifference>
    ) => {
      state.distanceDifference = action.payload;
    },
    setDuration: (state, action: PayloadAction<string>) => {
      state.duration = action.payload;
    },
    setIsUnitsSwitchAccessible: (state, action: PayloadAction<boolean>) => {
      state.isUnitsSwitchAccessible = action.payload;
    },
    resetTripInfoState: () => initialState,
  },
});

export const {
  setActiveUnitSystem,
  setFuelPrice,
  setTollCost,
  resetTollCost,
  resetTripInfoState,
  setDistance,
  setDuration,
  setDistanceDifference,
  setLoading,
  setIsUnitsSwitchAccessible,
} = tripInfoSlice.actions;

export default tripInfoSlice.reducer;
