import { useAppSelector } from "@/store/app/hooks";
import {
  carrierReportDataSelector,
  carrierReportLoadingSelector,
  isCarrierReportAvailableSelector,
  isCarrierReportLockSelector,
} from "@/store/features/carrier-report/carrierReportSelectors";
import { InDevelopmentView } from "@/components/in-development-view";
import { Loader } from "@/components/loader";

import { AuthorityStatuses } from "./AuthorityStatuses";
import { DBAData } from "./DBAData";
import { Heading } from "./Heading";
import { ECapitalData } from "./ECapitalData";
import { Button, Link } from "@nextui-org/react";

import { ReactComponent as WarningIcon } from "@/assets/icons/warning.svg";
import { useMemo } from "react";
import { ExpandLoadView } from "../expand-load-view";
import { LockedView } from "./LockedView";

export const CarrierReportView = () => {
  const carrierReportData = useAppSelector(carrierReportDataSelector);
  const isCarrierReportAvaiable = useAppSelector(
    isCarrierReportAvailableSelector
  );
  const isCarrierReportLock = useAppSelector(isCarrierReportLockSelector);
  const loading = useAppSelector(carrierReportLoadingSelector);
  const href = `https://loadconnect.io/trucking/${carrierReportData?.dotNumber}`;

  const render = useMemo(() => {
    if (loading) {
      return <Loader show={loading} />;
    }
    if (!isCarrierReportAvaiable) {
      return <InDevelopmentView />;
    }
    if (!carrierReportData) {
      return <ExpandLoadView />;
    }
    return (
      <>
        <Heading />
        {isCarrierReportLock && <LockedView />}
        <DBAData />
        <AuthorityStatuses />
        {!isCarrierReportLock && <ECapitalData />}
        <div className="w-full col-span-3 flex gap-4 items-center px-4 py-3 rounded-xl bg-brand-blue-50 text-brand-blue-700 text-sm">
          <WarningIcon className="w-6 h-6" />
          <div>
            <p className="font-medium">Disclaimer</p>
            <p className="text-gray-800 font-normal">
              Please note: past performance doesn’t guarantee future payment 
            </p>
          </div>
        </div>
        {!!carrierReportData?.dotNumber && (
          <div className="col-span-3 w-full flex justify-end mt-2">
            <Button
              as={Link}
              href={href}
              target="_blank"
              variant="bordered"
              color="default"
              className="self-end"
            >
              See the full page
            </Button>
          </div>
        )}
      </>
    );
  }, [carrierReportData, href, isCarrierReportAvaiable, loading]);

  return <div className="grid grid-cols-3 gap-2">{render}</div>;
};
