import { Tooltip } from "@nextui-org/react";
import { FC } from "react";
import { ReactComponent as InfoIcon } from "@/assets/icons/info.svg";

interface Props {
  tooltip?: string;
}

export const IconTooltip: FC<Props> = ({ tooltip }) => {
  return (
    <Tooltip content={tooltip} size="md" className="max-w-44">
      <InfoIcon />
    </Tooltip>
  );
};
