import { FC } from "react";
import { TripDetails } from "./TripDetails";
import { EquipmentDetails } from "./EquipmentDetails";
import { CompanyDetails } from "./CompanyDetails";

export const LoadInfoView: FC = () => {
  return (
    <div className="flex flex-col gap-4">
      <TripDetails />
      <EquipmentDetails />
      <CompanyDetails />
    </div>
  );
};
