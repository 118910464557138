import { cn } from "@nextui-org/theme";
import { FC, ReactNode } from "react";

interface IProps {
  label?: string;
  value?: ReactNode;
  subValue?: string;
  className?: string;
}

export const Data: FC<IProps> = ({ label, value, subValue, className }) => {
  const valueClassName = cn(
    "text-gray-950",
    "text-sm",
    "font-medium",
    "text-end",
    className
  );

  return (
    <div className="flex justify-between items gap-2">
      <div className="w-full flex justify-between items-center">
        <span className="text-gray-500 text-sm">{label}</span>
      </div>
      <div className="flex flex-col items-end shrink-0">
        <span className={valueClassName}>{value}</span>
        <span className="text-gray-600 text-sm text-end">{subValue}</span>
      </div>
    </div>
  );
};
