import { useAppSelector } from "@/store/app/hooks";
import { loadDataSelector } from "@/store/features/load-info/loadInfoSelectors";
import { ReactComponent as GoogleMapIcon } from "@/assets/icons/google-map-icon.svg";

import { Button, Link } from "@nextui-org/react";
import { useMemo } from "react";
import { sendAmplitudeData } from "@/analytics";
import { AMPLITUDE_EVENTS } from "@/analytics/events";

export const OpenGoogleMaps = () => {
  const loadData = useAppSelector(loadDataSelector);

  const { origin, destination, truck_location } = loadData || {};

  const href = useMemo(() => {
    const base = "https://www.google.com/maps/dir/";
    if (truck_location)
      return base + `${truck_location}/${origin}/${destination}`;
    return base + `${origin}/${destination}`;
  }, [destination, origin, truck_location]);

  const sendAnalytics = () => {
    sendAmplitudeData(AMPLITUDE_EVENTS.popupGoogleMapsClicked);
  };

  return (
    <Button
      className="absolute z-50 top-2 left-2 py-2 px-[12px] bg-white text-brand-blue-700 font-semibold text-sm h-auto"
      startContent={<GoogleMapIcon />}
      color="default"
      radius="sm"
      href={href}
      as={Link}
      onClick={sendAnalytics}
      target="_blank"
    >
      View in Google Maps
    </Button>
  );
};
