import { FC, ReactNode, useMemo } from "react";

interface IProps {
  title: string;
  className?: string;
  value?: ReactNode;
  children?: ReactNode;
}

export const Stats: FC<IProps> = ({ className, title, value, children }) => {
  const renderValue = useMemo(
    () => value || (children ? "" : "-"),
    [children, value]
  );
  return (
    <div className={className}>
      <h4 className="text-sm text-gray-500 first-letter:capitalize">{title}</h4>
      {renderValue && (
        <div className="text-sm font-medium text-gray-950">{renderValue}</div>
      )}
      {children && <div className="text-sm text-gray-600">{children}</div>}
    </div>
  );
};
