export const formatDate: (value?: string) => string = (dateStr) => {
  if (!dateStr) return "";
  const date = new Date(dateStr);
  return date.toLocaleDateString("en-US");
};

export const toShortMonthDayYear = (dateStr?: string) => {
  if (!dateStr) return "";

  const date = new Date(dateStr);
  return date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
};

export const toMilliseconds = (age?: string) => {
  if (!age) return 0;

  if (age.includes("min") || age.includes("m")) {
    return parseInt(age) * 60000;
  }

  if (
    age.includes("hr") ||
    age.includes("hrs") ||
    age.includes("h") ||
    age.includes("hour") ||
    age.includes("hours")
  ) {
    return parseInt(age) * 3600000;
  }

  return Date.now() - new Date(age).getTime();
};

export function getLocalISODate(timeInMs?: number): string {
  const date = timeInMs ? new Date(timeInMs) : new Date();
  const offset = date.getTimezoneOffset() * 60000;
  const localISOTime = new Date(date.getTime() - offset).toISOString();

  return localISOTime.slice(0, -1) + "2";
}
