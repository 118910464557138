import { Snippet } from "@nextui-org/snippet";

import { ReactComponent as RouteIcon } from "@/assets/icons/route.svg";
import { ReactComponent as PathIcon } from "@/assets/icons/path.svg";
import { ReactComponent as CopyIcon } from "@/assets/icons/copy.svg";

import { Group } from "@/components/group";
import { Divider } from "@/components/divider";

import { Block } from "./Block";
import { Stats } from "./Stats";
import { loadDataSelector } from "@/store/features/load-info/loadInfoSelectors";
import { useAppSelector } from "@/store/app/hooks";

export const TripDetails = () => {
  const loadData = useAppSelector(loadDataSelector);

  const {
    rate,
    origin,
    destination,
    truck_location,
    trip,
    pickup_date,
    delivery_date,
    order_number,
    refId,
    load_type,
  } = loadData || {};

  return (
    <Block heading="Trip & load details">
      <div className="flex gap-5 items-center">
        <Stats
          className="flex gap-2 items-baseline"
          title="Ref number"
          value={refId}
        />
        <div className="flex gap-2 items-center">
          <Stats
            className="flex gap-2 items-baseline"
            title="Order number"
            value={order_number}
          />
          {order_number && (
            <Snippet
              copyIcon={<CopyIcon />}
              classNames={{ base: "p-0 gap-0 bg-0", copyButton: "p-0" }}
              hideSymbol
              codeString={order_number}
            />
          )}
        </div>
      </div>
      <Group>
        <div className="flex flex-col gap-3">
          <div className="flex gap-2">
            <RouteIcon />
            <p className="font-semibold text-sm text-brand-blue-700">
              Trip {trip}
            </p>
          </div>

          <div className="flex justify-between gap-5">
            <div className="w-full flex gap-3">
              <PathIcon className="shrink-0" />
              <div className="grid grid-cols-4 gap-y-3 gap-x-4">
                <Stats
                  className="col-span-2"
                  title="Pickup date"
                  value={pickup_date}
                />
                <Stats title="Origin" value={origin} />
                <Stats title="Dead Head" value={truck_location} />
                <Stats
                  className="col-span-2"
                  title="Delivery date"
                  value={delivery_date}
                />
                <Stats title="Destination" value={destination} />
              </div>
            </div>

            <Divider />
            <div className="flex flex-col gap-5 shrink-0 w-40">
              <Stats title="Rate" value={rate} />
              <Stats title="Load type" value={load_type} />
            </div>
          </div>
        </div>
      </Group>
    </Block>
  );
};
