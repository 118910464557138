import { ReactComponent as Logo } from "@/assets/icons/logo.svg";

export const Brand = () => {
  return (
    <div className="bg-gray-100 flex gap-2 justify-center items-center">
      <span className="text-xs text-gray-500">Powered by</span>
      <Logo />
    </div>
  );
};
