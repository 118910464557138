import { DataGroup } from "./DataGroup";
import { Data } from "./Data";
import { useAppSelector } from "@/store/app/hooks";
import { carrierReportDataSelector } from "@/store/features/carrier-report/carrierReportSelectors";

import { ReactComponent as InsuranceIcon } from "@/assets/icons/certificate.svg";
import { ReactComponent as LoadingIcon } from "@/assets/icons/loading.svg";
import { getDateDifference } from "@/utils/getDateDifference";
import { toMoneyFormat } from "@/utils/toMoneyFormat";
import { toShortMonthDayYear } from "@/utils/formatDate";

const statuses: Record<string, string> = {
  A: "Active",
  I: "Inactive",
  N: "Not Authorized",
  "-": "-",
};

const fileStatuses: Record<string, string> = {
  Y: "Yes",
  N: "No",
  "-": "-",
};

export const AuthorityStatuses = () => {
  const carrierReportData = useAppSelector(carrierReportDataSelector);
  const { carrierAuth } = carrierReportData || {};

  const {
    bipdFile,
    cargoFile,
    bondFile,
    brokerStat,
    brokerStatDate,
    freightForwarderStat,
    freightForwarderDate,
  } = carrierAuth || {
    bipdFile: 0,
    cargoFile: "-",
    bondFile: "-",
    commonStat: "N",
    commonStatDate: "",
    brokerStat: "N",
    brokerStatDate: "",
    freightForwarderStat: "N",
    freightForwarderDate: "",
  };

  return (
    <>
      <DataGroup
        header="Broker authority"
        icon={<LoadingIcon />}
        danger={brokerStat !== "A"}
        tooltip={
          brokerStat !== "A"
            ? "It seems this broker does not have an active Broker authority listed on FMCSA. Please double-check to confirm."
            : ""
        }
      >
        <Data label="Status" value={statuses[brokerStat] || "-"} />
        {!!brokerStatDate && (
          <Data
            label="Since"
            value={toShortMonthDayYear(brokerStatDate)}
            subValue={getDateDifference(brokerStatDate)}
          />
        )}
      </DataGroup>
      <DataGroup
        header="Forwarder authority"
        icon={<LoadingIcon />}
        danger={freightForwarderStat !== "A"}
      >
        <Data label="Status" value={statuses[freightForwarderStat] || "-"} />
        {!!freightForwarderDate && (
          <Data
            label="Since"
            value={toShortMonthDayYear(freightForwarderDate)}
            subValue={getDateDifference(freightForwarderDate)}
          />
        )}
      </DataGroup>
      <DataGroup
        icon={<InsuranceIcon />}
        header="Insurance"
        danger={
          (!cargoFile || cargoFile === "N") &&
          (!bondFile || bondFile === "N") &&
          +bipdFile === 0
        }
      >
        <Data label="BIPD" value={toMoneyFormat(+bipdFile * 1000)} />
        <Data label="Cargo" value={fileStatuses[cargoFile] || "-"} />
        <Data label="BOND" value={fileStatuses[bondFile] || "-"} />
      </DataGroup>
    </>
  );
};
