import { ILoadDataV2, IRouteRequest, TDistance } from "@/types/common";
import geoData from "@/assets/data/usa-geo.json";
import polyline from "@mapbox/polyline";

export const getDistance = (legs: any[]) => {
  const meters = legs.reduce((acc, leg) => acc + leg.distance.value, 0);
  const miles = Math.round(meters * 0.000621371);
  const kilometers = Math.round(meters / 1000);

  const data: TDistance = {
    kilometers: kilometers + " km",
    miles: miles + " mi",
    kilometersRaw: kilometers,
    milesRaw: miles,
  };

  return data;
};

export const getDuration = (legs: any[]) => {
  let seconds = legs.reduce((acc, leg) => acc + leg.duration.value, 0);

  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);

  return `${hours} h ${minutes} m`;
};

export const getFullLocation = (location?: string) => {
  if (!location) {
    return "";
  }
  if (location.length < 3) {
    return (
      geoData.features.find((feature) => feature.properties.id === location)
        ?.properties.name || ""
    );
  }

  return location;
};

export const getPolyline = (legs: any) => {
  const points = legs
    .map((x: any) => x.steps)
    .reduce(flatten)
    .map((x: any) => x.polyline.points)
    .map((x: any) => polyline.decode(x))
    .reduce(flatten);

  return polyline.encode(points);
};

const flatten = (arr: any[], x: any) => arr.concat(x);

export const getRequestData = (loadData: ILoadDataV2): IRouteRequest => {
  if (loadData.truck_location) {
    return {
      origin: getFullLocation(loadData.truck_location),
      destination: getFullLocation(loadData.destination),
      waypoints: [{ location: getFullLocation(loadData.origin) }],
      travelMode: google.maps.TravelMode.DRIVING,
      unitSystem: google.maps.UnitSystem.IMPERIAL,
      avoidTolls: false,
    };
  } else {
    return {
      origin: getFullLocation(loadData.origin),
      destination: getFullLocation(loadData.destination),
      travelMode: google.maps.TravelMode.DRIVING,
      unitSystem: google.maps.UnitSystem.IMPERIAL,
      avoidTolls: false,
    };
  }
};
