import React, { FC } from "react";
import { Button, ButtonProps } from "@nextui-org/react";

interface IProps extends ButtonProps {}

export const StyledButton: FC<IProps> = (props) => {
  return (
    <Button className="h-10 min-w-10 shrink-0" {...props}>
      {props.children}
    </Button>
  );
};
