import { FC, ReactElement } from "react";

interface IProps {
  label: string;
  value?: string;
  icon?: ReactElement;
}

export const MetricCard: FC<IProps> = ({ icon, label, value = "-" }) => {
  return (
    <div className="bg-white border-1 border-gray-100 rounded-lg p-2 flex flex-col gap-1">
      <h4 className="text-gray-950 text-xs">{label}</h4>
      <div className="flex items-center gap-2">
        {icon}
        <p className="text-gray-950 text-sm font-medium">{value}</p>
      </div>
    </div>
  );
};
