import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ITabMenuItem, TabValue } from "@/types/common";

export interface ITabsState {
  list: ITabMenuItem[];
  activeTab: TabValue;
}

const initialState: ITabsState = {
  list: [
    { label: "Email", value: "email" },
    { label: "Load info", value: "loadInfo" },
    {
      label: "Broker report",
      value: "carrierReport",
    },
  ],
  activeTab: "email",
};

export const tabsSlice = createSlice({
  name: "tabs",
  initialState,
  reducers: {
    setActiveTab: (state, action: PayloadAction<TabValue>) => {
      state.activeTab = action.payload;
    },
    resetTabsState: () => initialState,
  },
});

export const { setActiveTab, resetTabsState } = tabsSlice.actions;

export default tabsSlice.reducer;
