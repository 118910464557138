import { FC, ReactNode } from "react";
import classNames from "classnames";

interface iProps {
  className?: string;
  children: ReactNode;
}

export const Group: FC<iProps> = ({ className, children }) => {
  const cn = classNames(
    "p-3",
    "border-1",
    "rounded-lg",
    "border-gray-100",
    className
  );
  return <div className={cn}>{children}</div>;
};
