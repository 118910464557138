import { ReactComponent as StatsIcon } from "@/assets/icons/stats.svg";
import { ReactComponent as AlertIcon } from "@/assets/icons/alert.svg";
import { ReactComponent as FMCSAIcon } from "@/assets/icons/fmcsa.svg";
import { ReactComponent as LightningEmoji } from "@/assets/img/lightning-emoji.svg";
import { ReactComponent as UnlockIcon } from "@/assets/icons/unlock.svg";
import { Button, Link } from "@nextui-org/react";
import { PRICING_LINK } from "@/constants/links";

const FEATURES = [
  {
    icon: <StatsIcon />,
    title: "Broker Factoring",
    description:
      "Quickly see the broker credit rating and score to make a right decision.",
  },
  {
    icon: <AlertIcon />,
    title: "Alerts",
    description: "Quickly see the if the broker is safe for the partnership.",
  },
  {
    icon: <FMCSAIcon />,
    title: "FMCSA data",
    description:
      "Find the most important information about the broker from FMCSA.",
  },
];

export const LockedView = () => {
  return (
    <div className="absolute top-0 left-0 right-0 bottom-0 backdrop-blur-md bg-white/70 z-10  flex flex-col justify-center items-center gap-6">
      <LightningEmoji />
      <div className="flex flex-col gap-2 items-center max-w-[400px]">
        <h3 className="text-gray-800 font-semibold text-2xl">
          Unlock Broker Report
        </h3>
        <p className="text-sm text-gray-800">
          For a $10/month enjoy powerful tools like:
        </p>
      </div>
      <div className="flex flex-col gap-2">
        {FEATURES.map((feature) => (
          <div
            key={feature.title}
            className="flex gap-3 bg-[#F3F7FE] max-w-[400px] p-3 rounded-lg"
          >
            <div className="flex-shrink-0">{feature.icon}</div>
            <div className="flex flex-col gap-1">
              <p className="text-sm text-gray-950 font-medium">
                {feature.title}
              </p>
              <p className="text-sm text-gray-600">{feature.description}</p>
            </div>
          </div>
        ))}
      </div>
      <Button
        startContent={<UnlockIcon />}
        as={Link}
        href={PRICING_LINK}
        target="_blank"
        color="primary"
      >
        Unlock more
      </Button>
    </div>
  );
};
