import { IEmailData, ITokens } from "@/types/common";
import { EmailSendStatus } from "../constants/email-send-status";
import { EmailVariables } from "@/constants/email-variables";

export const fetchApi = async (
  url: string,
  options: any,
  accessToken: string,
  refreshToken: string
) => {
  const response = await fetch(url, options);

  if (response.status === 401 && options.headers.Authorization) {
    const token: any = await refreshTokenApi(accessToken, refreshToken);
    options.headers.Authorization = `Bearer ${token.accessToken}`;
    const response2 = await fetch(url, options);

    return response2;
  }

  return response;
};

export const refreshTokenApi = async (
  accessToken: string,
  refreshToken: string
) => {
  const data = await new Promise((resolve, reject) => {
    fetch(
      `https://api.carrierify.com/auth/refresh?refreshToken=${refreshToken}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });

  return data;
};

export const getAccounts = async (
  accessToken: string,
  refreshToken: string
) => {
  const resp = await fetchApi(
    "https://api.carrierify.com/account",
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
    accessToken,
    refreshToken
  );
  const data = await resp.json();
  return data;
};

export const getTemplates = async (
  accessToken: string,
  refreshToken: string
) => {
  const resp = await fetchApi(
    "https://api.carrierify.com/templates",
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
    accessToken,
    refreshToken
  );
  const data = await resp.json();
  return data;
};

export const sendEmailV3 = async (
  toEmail: string,
  subject: string,
  data: string,
  fromEmail: string,
  accessToken: string,
  refreshToken: string
) => {
  const response = await fetchApi(
    `https://api.carrierify.com/email/send/v3`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        to: toEmail,
        subject: subject,
        fromEmail: fromEmail,
        html: data,
      }),
    },
    accessToken,
    refreshToken
  );
  return getEmailSendStatus(response);
};

export const getFuelPriceApi = async (lat: any, lon: any) => {
  const data = await fetch(
    `https://ref.loadconnect.io/fuel?lat=${lat}&lon=${lon}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  const resp = await data.json();
  return resp;
};

export const getTollApi = async (polyline: string) => {
  const data = await fetch("https://ref.loadconnect.io/toll", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ polyline }),
  });
  const resp = await data.json();
  return resp;
};

export const getCarrierReport = async (
  mc: string | number,
  tokens: ITokens
) => {
  const { accessToken, refreshToken } = tokens;
  const data = await fetchApi(
    `https://api.carrierify.com/api/v1/companies/${mc}?expanded=true`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    },
    accessToken,
    refreshToken
  );
  const resp = await data.json();
  return resp;
};

export const updatePricePrecision = async (fuelPrice: any) => {
  try {
    fuelPrice.gasoline = Number(fuelPrice.gasoline).toFixed(2);
    fuelPrice.diesel = Number(fuelPrice.diesel).toFixed(2);
    fuelPrice.lpg = Number(fuelPrice.lpg).toFixed(2);
  } catch (e) {}

  return fuelPrice;
};

export const getEmailSendStatus = (response: any): EmailSendStatus => {
  switch (response.status) {
    case 200:
    case 201:
      return EmailSendStatus.EMAIL_SENT;
    case 401:
      return EmailSendStatus.UNAUTHORIZED;
    case 409:
      return EmailSendStatus.VERIFIFICATION_REQUIRED;
    case 501:
      return EmailSendStatus.RECHARGE;
    default:
      return EmailSendStatus.ERROR;
  }
};

export const reformatText = (text: string = "", data?: IEmailData) => {
  const destination = data?.dest || data?.destination || "";
  if (data) {
    text = text.replaceAll(EmailVariables.ORIGIN, data.origin || "");
    text = text.replaceAll(EmailVariables.DEST, destination || "");
    text = text.replaceAll(EmailVariables.AVAIL, data.avail || "");
    text = text.replaceAll(EmailVariables.TRUCK, data.truck || "");
    text = text.replaceAll(EmailVariables.EMAIL, data.email || "");
    text = text.replaceAll(EmailVariables.COMPANY, data.company || "");
    text = text.replaceAll(EmailVariables.RATE, data.rate || "");
    text = text.replaceAll(EmailVariables.REF, data.ref || "");
    text = text.replaceAll(EmailVariables.TRIP, data.trip || "");
    text = text.replaceAll(EmailVariables.DO, data.do || "");
  }

  return text || "";
};

export const getUserInfo = async (
  accessToken: string,
  refreshToken: string
) => {
  const resp = await fetchApi(
    "https://api.carrierify.com/users/me",
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
    accessToken,
    refreshToken
  );
  const data = await resp.json();
  return data;
};

export const sendUserAnalytics = async (
  accessToken: string,
  refreshToken: string,
  eventData: Record<string, string | number>
) => {
  await fetchApi(
    "https://analytics.loadconnect.io/api/load-event",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(eventData),
    },

    accessToken,
    refreshToken
  );
};
