import { FC } from "react";
import { TabMenu } from "../tab-menu";
import { EmailView } from "@/components/email-view";
import { LoadInfoView } from "@/components/load-info-view";
import { CarrierReportView } from "@/components/carrier-report-view";
import { useAppSelector } from "@/store/app/hooks";
import { activeTabSelector } from "@/store/features/tabs/tabsSelectors";

const VIEWS = {
  email: <EmailView />,
  loadInfo: <LoadInfoView />,
  carrierReport: <CarrierReportView />,
};

export const MainView: FC = () => {
  const activeTab = useAppSelector(activeTabSelector);

  return (
    <div className="flex flex-col">
      <TabMenu />
      <div className="overflow-y-auto overflow-x-hidden h-full p-4 relative">
        {VIEWS[activeTab]}
      </div>
    </div>
  );
};
