import { createAsyncThunk } from "@reduxjs/toolkit";
import { getUserInfo } from "@/modules/server";
import { ITokens } from "@/types/common";

export const getUserInfoAsync = createAsyncThunk(
  "account/getUserInfo",
  async (tokens: ITokens) => {
    const { accessToken, refreshToken } = tokens;
    const response = await getUserInfo(accessToken, refreshToken);
    return response;
  }
);
