import { FC, useMemo } from "react";
import {
  Autocomplete,
  AutocompleteItem,
  AutocompleteProps,
} from "@nextui-org/react";
import { IAccount } from "@/types/common";

interface IProps extends Omit<AutocompleteProps, "children"> {
  options: IAccount[];
}

const AccountSelectFieldDescription = () => (
  <p>
    Please verify your account and visit the{" "}
    <a
      className="text-brand-blue-700 underline"
      href="https://app.loadconnect.io/email"
      target="_blank"
      rel="noreferrer"
    >
      following link
    </a>
  </p>
);

export const AccountSelectField: FC<IProps> = ({
  options,
  selectedKey,
  ...rest
}) => {
  const disabledKeys = useMemo(
    () =>
      options
        .filter((option) => !option.verified)
        .map((option) => option.id.toString()),
    [options]
  );

  return (
    <Autocomplete
      {...rest}
      selectedKey={selectedKey}
      labelPlacement="outside"
      variant="bordered"
      size="md"
      inputProps={{
        classNames: {
          inputWrapper:
            "border-1 border-gray-200 text-gray-950 shadow-none rounded-lg",
        },
      }}
      isInvalid={disabledKeys.includes(selectedKey as string)}
      errorMessage={(result) =>
        result.isInvalid && <AccountSelectFieldDescription />
      }
    >
      {options.map((item) => (
        <AutocompleteItem
          key={item.id}
          value={item.id}
          classNames={{
            title:
              disabledKeys.includes(item.id.toString()) && "text-error-500",
          }}
        >
          {item.email}
        </AutocompleteItem>
      ))}
    </Autocomplete>
  );
};
