import React, { ReactNode } from "react";
import { Badge } from "@nextui-org/react";
import { ITabMenuItem, TabValue } from "@/types/common";
import classNames from "classnames";

interface IProps {
  isActive: boolean;
  data: ITabMenuItem;
  onClick: (tab: TabValue) => void;
  icon: ReactNode;
  badge?: string;
}

export const TabMenuItem: React.FC<IProps> = ({
  isActive,
  data,
  onClick,
  icon,
  badge,
}) => {
  const { label, value } = data;

  const cn = classNames(
    "h-full",
    "w-full",
    "mt-2",
    "flex",
    "justify-center",
    "items-center",
    "font-semibold",
    "text-sm",
    "gap-2",
    isActive ? "text-brand-blue-700" : "text-gray-600",
    isActive ? "bg-white" : "bg-none",
    "rounded-t-xl",
    "cursor-pointer",
    "hover:text-brand-blue-700"
  );

  return (
    <div className={cn} onClick={() => onClick(value)}>
      {icon}
      <Badge
        isInvisible={!badge}
        content={badge}
        color="primary"
        classNames={{
          badge: "border-none -top-1 font-normal text-xs",
        }}
      >
        <span>{label}</span>
      </Badge>
    </div>
  );
};
