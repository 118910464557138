import { FC, ReactNode } from "react";
import { Loader } from "../loader";
import { useAppSelector } from "@/store/app/hooks";
import { loadingEmailSelector } from "@/store/features/email/emailSelectors";
import "./style.css";

interface IProps {
  children: ReactNode | ReactNode[];
}

export const Layout: FC<IProps> = ({ children }) => {
  const loading = useAppSelector(loadingEmailSelector);

  return (
    <>
      <div className="layout-grid">{children}</div>
      <Loader show={loading} />
    </>
  );
};
