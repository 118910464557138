import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ILoadDataV2 } from "@/types/common";

export interface ILoadInfoState {
  data?: ILoadDataV2;
  includeDeadHead: boolean;
  source?: string;
}

const initialState: ILoadInfoState = {
  data: undefined,
  includeDeadHead: false,
  source: "",
};

export const loadInfoSlice = createSlice({
  name: "loadInfo",
  initialState,
  reducers: {
    setLoadData: (state, action: PayloadAction<ILoadDataV2>) => {
      state.data = action.payload;
      state.includeDeadHead = !!action.payload.truck_location;
    },
    setIncludeDeadHead: (state, action: PayloadAction<boolean>) => {
      state.includeDeadHead = action.payload;
    },
    setSource: (state, action: PayloadAction<string>) => {
      state.source = action.payload;
    },
    resetLoadInfoState: () => initialState,
  },
});

export const {
  setLoadData,
  setIncludeDeadHead,
  resetLoadInfoState,
  setSource,
} = loadInfoSlice.actions;

export default loadInfoSlice.reducer;
