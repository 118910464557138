import { FC, ReactNode } from "react";
import { Heading } from "./Heading";

interface IProps {
  heading: string;
  children: ReactNode;
}

export const Block: FC<IProps> = ({ heading, children }) => {
  return (
    <div className="flex flex-col gap-3">
      <Heading>{heading}</Heading>
      {children}
    </div>
  );
};
