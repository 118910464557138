import { configureStore } from "@reduxjs/toolkit";
import loadInfoSlice from "@/store/features/load-info/loadInfoSlice";
import emailSlice from "@/store/features/email/emailSlice";
import accountSlice from "@/store/features/account/accountSlice";
import tripInfoSlice from "@/store/features/trip-info/tripInfoSlice";
import tabsSlice from "@/store/features/tabs/tabsSlice";
import carrierReportSlice from "@/store/features/carrier-report/carrierReportSlice";

export const store = configureStore({
  reducer: {
    loadInfo: loadInfoSlice,
    tripInfo: tripInfoSlice,
    email: emailSlice,
    account: accountSlice,
    tabs: tabsSlice,
    carrierReport: carrierReportSlice,
  },
});

export type TRootState = ReturnType<typeof store.getState>;
export type TAppDispatch = typeof store.dispatch;
