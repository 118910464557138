import { Group } from "@/components/group";

import { Block } from "./Block";
import { Stats } from "./Stats";
import { Note } from "./Note";

import { TEquipment } from "@/types/common";
import { removeUnderscore } from "@/utils/removeUnderscore";
import { useAppSelector } from "@/store/app/hooks";
import { loadDataSelector } from "@/store/features/load-info/loadInfoSelectors";

export const EquipmentDetails = () => {
  const loadData = useAppSelector(loadDataSelector);

  const { equipment } = loadData || {};

  const isEmpty = (item: any | any[]) =>
    !item || !item?.length || item?.every?.((element: any) => !element);

  if (!equipment) return <></>;

  return (
    <Block heading="Equipment">
      <Group>
        <div className="grid grid-cols-4 gap-3">
          {Object.keys(equipment).map((item) => {
            if (isEmpty(equipment[item as TEquipment])) return null;
            if (item === "comments")
              return (
                <Stats key={item} className="col-span-2" title="Notes">
                  <div className="mt-1 flex flex-col gap-2">
                    {equipment?.comments?.map(
                      (comment) =>
                        comment && <Note key={comment}>{comment}</Note>
                    )}
                  </div>
                </Stats>
              );
            return (
              <Stats
                key={item}
                title={removeUnderscore(item)}
                value={equipment?.[item as TEquipment]}
              />
            );
          })}
        </div>
      </Group>
    </Block>
  );
};
