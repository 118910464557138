import { Switch } from "@nextui-org/react";
import { useAppDispatch, useAppSelector } from "@/store/app/hooks";
import {
  includeDeadHeadSelector,
  loadDataSelector,
} from "@/store/features/load-info/loadInfoSelectors";
import { setIncludeDeadHead } from "@/store/features/load-info/loadInfoSlice";
import { useCallback } from "react";
import { tripDataSelector } from "@/store/features/trip-info/tripInfoSelectors";
import { sendAmplitudeData } from "@/analytics";
import { AMPLITUDE_EVENTS } from "@/analytics/events";

export const IncludeDeadHead = () => {
  const dispatch = useAppDispatch();
  const loadData = useAppSelector(loadDataSelector);
  const includeDeadHead = useAppSelector(includeDeadHeadSelector);
  const tripData = useAppSelector(tripDataSelector);

  const { activeUnitSystem, distanceDifference } = tripData || {};

  const handleChange = useCallback(
    (isSelected: boolean) => {
      dispatch(setIncludeDeadHead(isSelected));
      sendAmplitudeData(AMPLITUDE_EVENTS.popupDeadheadClicked);
    },
    [dispatch]
  );

  const differenceValue =
    activeUnitSystem === "imperial"
      ? distanceDifference.miles
      : distanceDifference.kilometers;

  const differcenceTerm = activeUnitSystem === "imperial" ? "mi" : "km";

  if (!loadData?.truck_location) return <></>;

  return (
    <div className="bg-white col-span-2 rounded-lg p-2 flex justify-between items-center">
      <div className="flex gap-2 text-sm">
        <p className="text-gray-500">Include dead head</p>
        <p className="text-gray-950 font-medium">
          +{differenceValue} {differcenceTerm}
        </p>
      </div>
      <Switch
        isSelected={includeDeadHead}
        size="sm"
        classNames={{ wrapper: "mr-0" }}
        onValueChange={handleChange}
      />
    </div>
  );
};
