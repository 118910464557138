import { FC, ReactNode } from "react";
import { IconTooltip } from "@/components/icon-tooltip";
import { cn } from "@nextui-org/theme";

interface IProps {
  icon?: ReactNode;
  header?: string;
  danger?: boolean;
  children?: ReactNode;
  innerClassName?: string;
  outerClassName?: string;
  tooltip?: string;
}

export const DataGroup: FC<IProps> = ({
  icon,
  header,
  danger = false,
  children,
  innerClassName,
  outerClassName,
  tooltip,
}) => {
  const className = cn(
    "p-3",
    "rounded-lg",
    "flex",
    "flex-col",
    "gap-3",
    {
      "bg-error-50 text-error-500": danger,
      "bg-success-50 text-success-600": !danger,
    },
    outerClassName
  );

  const innerCn = cn("flex", "flex-col", "gap-1", innerClassName);

  return (
    <div className={className}>
      {icon || header ? (
        <div className="w-full flex justify-between items-center">
          <div className="flex gap-2 items-center text-sm">
            {icon}
            {header}
          </div>
          {!!tooltip && <IconTooltip tooltip={tooltip} />}
        </div>
      ) : null}
      <div className={innerCn}>{children}</div>
    </div>
  );
};
