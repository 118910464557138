import { TRootState } from "@/store/app/store";

export const loadingEmailSelector = (state: TRootState) => state.email.loading;

export const activeTemplateSelector = (state: TRootState) =>
  state.email.activeTemplateId;

export const accountsSelector = (state: TRootState) => state.email.accounts;
export const templatesSelector = (state: TRootState) => state.email.templates;
export const senderEmailKeySelector = (state: TRootState) =>
  state.email.senderEmailKey;
export const recieverEmailSelector = (state: TRootState) =>
  state.email.recieverEmail;
export const emailDataSelector = (state: TRootState) => state.email.emailData;
export const emailStatusSelector = (state: TRootState) => state.email.emailStatus;

export const activeTemplateDataSelector = (state: TRootState) =>
  state.email.activeTemplateData;
