import { TRootState } from "@/store/app/store";

export const carrierReportDataSelector = (state: TRootState) =>
  state.carrierReport.data;
export const isCarrierReportAvailableSelector = (state: TRootState) =>
  state.carrierReport.isCarrierReportAvailable;
export const isCarrierReportLockSelector = (state: TRootState) =>
  state.carrierReport.isCarrierReportLocked;
export const carrierReportLoadingSelector = (state: TRootState) =>
  state.carrierReport.loading;
