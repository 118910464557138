import { ReactComponent as PhoneIcon } from "@/assets/icons/phone.svg";
import { ReactComponent as EmailIcon } from "@/assets/icons/email.svg";

import { Group } from "@/components/group";
import { ContactChip } from "@/components/contact-chip";

import { Block } from "./Block";
import { Stats } from "./Stats";

import { useAppDispatch, useAppSelector } from "@/store/app/hooks";
import { loadDataSelector } from "@/store/features/load-info/loadInfoSelectors";
import { setRecieverEmail } from "@/store/features/email/emailSlice";
import { setActiveTab } from "@/store/features/tabs/tabsSlice";
import { emailDataSelector } from "@/store/features/email/emailSelectors";
import { sendAmplitudeData } from "@/analytics";
import { AMPLITUDE_EVENTS } from "@/analytics/events";

export const CompanyDetails = () => {
  const dispatch = useAppDispatch();
  const loadData = useAppSelector(loadDataSelector);
  const emailData = useAppSelector(emailDataSelector);

  const { company, contact, docket, phone } = loadData || {};
  const { email } = emailData || {};

  const navigateToEmail = () => {
    dispatch(setRecieverEmail(contact));
    dispatch(setActiveTab("email"));
  };

  const renderEmail =
    email || (contact && contact?.includes("@") ? contact : "");

  const renderPhone = phone
    ? typeof phone === "string"
      ? phone
      : phone?.phone
    : "";

  const sendAnalytics = () =>
    sendAmplitudeData(AMPLITUDE_EVENTS.popupPhoneNumberClicked);

  return (
    <Block heading="Company">
      <Group>
        <div className="flex gap-3">
          <Stats title="Name" value={company}>
            {docket}
          </Stats>
          {renderEmail && (
            <Stats title="Email">
              <ContactChip
                onClick={navigateToEmail}
                color="success"
                icon={<EmailIcon />}
              >
                <span>{renderEmail}</span>
              </ContactChip>
            </Stats>
          )}
          {renderPhone && (
            <Stats title="Phone number">
              <ContactChip icon={<PhoneIcon />} onClick={sendAnalytics}>
                <a href={`tel:${renderPhone}`}>{renderPhone}</a>
              </ContactChip>
            </Stats>
          )}
        </div>
      </Group>
    </Block>
  );
};
