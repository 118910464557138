import { createSlice } from "@reduxjs/toolkit";
import { getCarrierReportAsync } from "./carrierReportAsyncThunks";
import { ICarrierReportData } from "@/types/carrierReport";

export interface ICarrierReportState {
  loading: boolean;
  data: ICarrierReportData | null;
  isCarrierReportAvailable: boolean;
  isCarrierReportLocked?: boolean;
}

const initialState: ICarrierReportState = {
  loading: false,
  data: null,
  isCarrierReportAvailable: false,
  isCarrierReportLocked: false,
};

export const carrierReportSlice = createSlice({
  name: "carrierReport",
  initialState,
  reducers: {
    setCarrierReportData: (state, action) => {
      state.data = action.payload;
    },
    setCarrierReportAvailability: (state, action) => {
      state.isCarrierReportAvailable = action.payload;
    },
    setCarrierReportLock: (state, action) => {
      state.isCarrierReportLocked = action.payload;
    },
    resetCarrierReportState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getCarrierReportAsync.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCarrierReportAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload.data;
    });
    builder.addCase(getCarrierReportAsync.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const {
  resetCarrierReportState,
  setCarrierReportData,
  setCarrierReportAvailability,
  setCarrierReportLock,
} = carrierReportSlice.actions;

export default carrierReportSlice.reducer;
