export enum EmailVariables {
  ORIGIN = "{{origin}}",
  DEST = "{{dest}}",
  AVAIL = "{{avail}}",
  TRUCK = "{{truck}}",
  EMAIL = "{{email}}",
  COMPANY = "{{company}}",
  RATE = "{{rate}}",
  REF = "{{ref}}",
  TRIP = "{{trip}}",
  DO = "{{do}}",
}
