import { FC } from "react";
import { Input, InputProps } from "@nextui-org/react";

interface IProps extends InputProps {}

export const AccountInputField: FC<IProps> = (props) => {
  return (
    <Input
      {...props}
      labelPlacement="outside"
      variant="bordered"
      size="md"
      classNames={{
        inputWrapper:
          "border-1 border-gray-200 text-gray-950 shadow-none rounded-lg",
      }}
    />
  );
};
