import { TRootState } from "@/store/app/store";

export const accountDataSelector = (state: TRootState) =>
  state.account.activeAccount;
export const accessTokenSelector = (state: TRootState) =>
  state.account.accessToken;
export const refreshTokenSelector = (state: TRootState) =>
  state.account.refreshToken;
export const userDataSelector = (state: TRootState) => state.account.userData;
export const featuresSelector = (state: TRootState) =>
  state.account.userData.features;
