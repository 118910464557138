import { FC } from "react";
import { TabMenuItem } from "./TabMenuItem";

import { ReactComponent as EmailIcon } from "@/assets/icons/email.svg";
import { ReactComponent as LoadInfoIcon } from "@/assets/icons/load-info.svg";
import { ReactComponent as CarrierReportIcon } from "@/assets/icons/carrier-report.svg";
import { useAppDispatch, useAppSelector } from "@/store/app/hooks";
import {
  activeTabSelector,
  tabsListSelector,
} from "@/store/features/tabs/tabsSelectors";
import { setActiveTab } from "@/store/features/tabs/tabsSlice";
import { TabValue } from "@/types/common";
import { sendAmplitudeData } from "@/analytics";
import { AMPLITUDE_EVENTS } from "@/analytics/events";

const ICONS = {
  email: <EmailIcon />,
  loadInfo: <LoadInfoIcon />,
  carrierReport: <CarrierReportIcon />,
};

const TABS_ANALYTIC_EVENTS: Record<TabValue, string> = {
  email: AMPLITUDE_EVENTS.popupEmailTabClicked,
  loadInfo: AMPLITUDE_EVENTS.popupLoadInfoTabClicked,
  carrierReport: AMPLITUDE_EVENTS.popupReportTabClicked,
};

export const TabMenu: FC = () => {
  const dispatch = useAppDispatch();
  const tabs = useAppSelector(tabsListSelector);
  const activeTab = useAppSelector(activeTabSelector);

  const handleTabClick = (tab: TabValue) => {
    const analyticEvent = TABS_ANALYTIC_EVENTS[tab];
    if (analyticEvent) sendAmplitudeData(analyticEvent);
    dispatch(setActiveTab(tab));
  };

  return (
    <div className="w-full flex justify-between bg-brand-blue-50 h-11 items-center">
      {tabs.map((tab) => (
        <TabMenuItem
          key={tab.value}
          isActive={activeTab === tab.value}
          data={tab}
          onClick={() => handleTabClick(tab.value)}
          icon={ICONS[tab.value]}
          badge={tab.value === "carrierReport" ? "beta" : ""}
        />
      ))}
    </div>
  );
};
