import { FC, useEffect, useState } from "react";
import { Layout } from "@/components/layout";
import { Header } from "@/components/header";
import { MapView } from "@/components/map-view";
import { CalculatorView } from "@/components/calculator-view";
import { MainView } from "@/components/main-view";
import { Brand } from "@/components/brand";
import { useAppDispatch } from "@/store/app/hooks";
import { ILoadDataV2, IMessage, ITokens } from "@/types/common";
import { setAmplitudeUserDevice } from "./analytics";
import { useSelector } from "react-redux";
import { accessTokenSelector } from "./store/features/account/accountSelectors";
import { resetStore } from "./utils/resetStore";
import { isEqual } from "lodash";

const App: FC = () => {
  const [prevData, setPrevData] = useState<ILoadDataV2 | null>(null);
  const dispatch = useAppDispatch();
  const currentAccess = useSelector(accessTokenSelector);

  useEffect(() => {
    const receiveMessage = async ({ data }: { data: IMessage | string }) => {
      if (typeof data === "object" && data?.data?.origin) {
        console.log("Message received: ", data.source);
        if (isEqual(prevData, data.data)) return;
        setPrevData(data.data);
        const isLoadLink = data.source.url.includes("loadlink");
        const tokens: ITokens = {
          accessToken: data.accessToken,
          refreshToken: data.refreshToken,
        };

        setAmplitudeUserDevice(data.deviceId);

        await resetStore(dispatch, {
          isLoadLink,
          tokens,
          data,
        });
      }
    };

    window.addEventListener("message", receiveMessage, false);
    return () => window.removeEventListener("message", receiveMessage, false);
  }, [currentAccess, dispatch, prevData]);

  return (
    <Layout>
      <Header />
      <MapView />
      <CalculatorView />
      <Brand />
      <MainView />
    </Layout>
  );
};

export default App;
