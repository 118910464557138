export const dummyFactoringData = {
  dotNumber: "No data",
  search: "No data",
  name: "No data",
  legalName: "No data",
  dbaName: "No data",
  addDate: "No data",
  mcs150Date: "No data",
  statusCode: "No data",
  docketNumbers: [],
  docketNumbersString: "No data",
  dunBradstreetNo: "No data",
  phyOmcRegion: "No data",
  safetyInvTerr: "No data",
  carrierOperation: "No data",
  businessOrgId: "No data",
  mcs150Mileage: "No data",
  mcs150MileageYear: "No data",
  mcs151Mileage: "No data",
  totalCars: "No data",
  mcs150UpdateCodeId: "No data",
  priorRevokeFlag: "No data",
  priorRevokeDotNumber: "No data",
  phone: "No data",
  fax: "No data",
  emailAddress: "No data",
  cellPhone: "No data",
  companyOfficer1: "No data",
  companyOfficer2: "No data",
  businessOrgDesc: "No data",
  truckUnits: "No data",
  powerUnits: "No data",
  busUnits: "No data",
  fleetSize: "No data",
  reviewId: "No data",
  recordableCrashRate: "No data",
  mailNationalityIndicator: "No data",
  phyNationalityIndicator: "No data",
  phyBarrio: "No data",
  mailBarrio: "No data",
  isBroker: "No data",
  isCarrier: "No data",
  isShipper: "No data",
  isRegistrant: "No data",
  isFreightForwarder: "No data",
  isIntermodalEquipmentProvider: "No data",
  isCargoTank: "No data",
  insuranceCoverageMax: "No data",
  pointNum: "No data",
  phyStreet: "No data",
  phyCity: "No data",
  phyCountry: "No data",
  phyState: "No data",
  phyZip: "No data",
  phyCnty: "No data",
  reviewType: "No data",
  reviewDate: "No data",
  safetyRating: "No data",
  safetyRatingDate: "No data",
  undelivPhy: "No data",
  totalIntrastateDrivers: "No data",
  mcsipStep: "No data",
  mcsipDate: "No data",
  hmInd: "No data",
  interstateBeyond100Miles: "No data",
  interstateWithin100Miles: "No data",
  intrastateBeyond100Miles: "No data",
  intrastateWithin100Miles: "No data",
  totalCdl: "No data",
  totalDrivers: "No data",
  avgDriversLeasedPerMonth: "No data",
  classDef: "No data",
  driverInterTotal: "No data",
  cargoCarried: [],
  ownTruck: "No data",
  ownTract: "No data",
  ownTrail: "No data",
  ownCoach: "No data",
  ownSchool: "No data",
  ownBus: "No data",
  ownVan: "No data",
  ownLimo: "No data",
  trmTruck: "No data",
  trmTract: "No data",
  trmTrail: "No data",
  trmCoach: "No data",
  trmSchool: "No data",
  trmBus: "No data",
  trmVan: "No data",
  trmLimo: "No data",
  trpTruck: "No data",
  trpTract: "No data",
  trpTrail: "No data",
  trpCoach: "No data",
  trpSchool: "No data",
  trpBus: "No data",
  trpVan: "No data",
  trpLimo: "No data",
  flatbed: "No data",
  reefer: "No data",
  dryVan: "No data",
  tanker: "No data",
  lowboy: "No data",
  stepDeck: "No data",
  conestoga: "No data",
  boxTruck: "No data",
  intermodalContainer: "No data",
  hopperBottom: "No data",
  livestockTrailer: "No data",
  carHauler: "No data",
  curtainside: "No data",
  dumpTrailer: "No data",
  chassisTrailer: "No data",
  extendableFlatbed: "No data",
  factoringInfo: {
    legalName: "No data",
    address1: "No data",
    address2: "No data",
    city: "No data",
    stateCode: "No data",
    country: "No data",
    postalCode: "No data",
    phone: "No data",
    email: "No data",
    last60AverageDaysToPay: "No data",
    last90AverageDaysToPay: "No data",
    allTimeAverageDaysToPay: "No data",
    creditLimitTotal: "No data",
    riskLevel: "No data",
    creditScore: "No data",
    eligibleForPurchase: 2,
    availableCredit: "No data",
    source: "No data",
  },
  carrierAuth: {
    docketNumber: "No data",
    mxType: "No data",
    rfcNumber: "No data",
    commonStat: "No data",
    contractStat: "No data",
    brokerStat: "No data",
    freightForwarderStat: "No data",
    bipdFile: "No data",
    cargoFile: "No data",
    bondFile: "No data",
    commonStatDate: "No data",
    contractStatDate: "No data",
    brokerStatDate: "No data",
    freightForwarderDate: "No data",
    authHistoriess: [],
  },
  carrierAuths: [],
  activeInsurances: [],
  inspections: [],
};
