import { createAsyncThunk } from "@reduxjs/toolkit";
import { getCarrierReport } from "@/modules/server";
import { ITokens } from "@/types/common";

export const getCarrierReportAsync = createAsyncThunk(
  "carrierReport/getCarrierReport",
  async (arg: { mc: string; tokens: ITokens }) => {
    const response = await getCarrierReport(arg.mc, arg.tokens);
    return response;
  }
);
