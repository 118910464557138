import { Spinner } from "@nextui-org/react";
import classNames from "classnames";
import { FC } from "react";

interface IProps {
  show?: boolean;
  dark?: boolean;
}

export const Loader: FC<IProps> = ({ dark = false, show = false }) => {
  const cn = classNames(
    "absolute",
    "top-0",
    "left-0",
    dark ? "bg-brand-700" : "bg-white",
    "bg-opacity-80",
    "w-full",
    "h-full",
    "flex",
    "justify-center",
    "items-center",
    "z-50"
  );
  if (!show) return <></>;

  return (
    <div className={cn}>
      <Spinner
        label="Just a second..."
        classNames={{
          label: dark ? "text-white" : "text-brand-blue-700",
          circle1: dark ? "border-b-white" : "border-b-brand-blue-700",
          circle2: dark ? "border-b-white" : "border-b-brand-blue-700",

        }}
      />
    </div>
  );
};
